import * as React from 'react'
import { Helmet } from 'react-helmet-async'

// The doc prop contains some metadata about the page being rendered that you can use.
const Wrapper = ({ children, doc }) => <React.Fragment>
    <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="/public/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
            name="description"
            content="Documentation for NanoAPI"
        />
        <meta charSet="utf-8" />
        <title>NanoAPI Doc</title>
    </Helmet>
    {children}
</React.Fragment>
export default Wrapper